import { styled } from '@mui/system'

const MiddleAreaWrapper = styled('div')`
	order: 1;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddle};
	display: flex;
	color: ${({ theme }) => theme.palette.footer.color};
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: 48px 75px 75px;
	}
	& .BHFooterMenus {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 20px 24px;
		${({ theme }) => theme.breakpoints.up('md')} {
			border-top: none;
			padding: 0;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
			}
		}
	}
`

const RightWrapper = styled('div')`
	display: flex;
	order: 2;
	flex-wrap: wrap;
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: column;
		border-top: none;
	}
	& .footer-social-links {
		order: 3;
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 13px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			display: block;
			order: 1;
			padding: 0;
		}
		& .MuiTypography-root {
			display: none;
			${({ theme }) => theme.breakpoints.up('md')} {
				display: block;
			}
		}
		& .BHSocialWrapper {
			${({ theme }) => theme.breakpoints.up('md')} {
				margin-bottom: ${({ theme }) => theme.spacing(1.25)};
			}
			& a {
				margin: 0;
				padding: 12px;
				${({ theme }) => theme.breakpoints.up('md')} {
					&:first-of-type {
						padding-left: 0;
					}
				}
			}
		}
	}

	& .footer-storelocator-link {
		/*
		order: 2;
		width: 50%;
		display: flex;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-radius: 0;
		padding: 30px 0;
		&:hover,
		&:active {
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			justify-content: flex-start;
			font-weight: bold;
			font-size: 1.2rem;
			border-bottom: none;
			padding: 0;
			margin-top: ${({ theme }) => theme.spacing(4)};
			min-height: 0;
		}
		*/
		display: none;
	}

	& .change-country-language-trigger-footer {
		order: 1;
		flex-grow: 1;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 30px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			flex-grow: 0;
			padding: 0;
			order: 3;
			width: 100%;
			justify-content: flex-start;
			border-bottom: none;
			border-right: none;
			margin-top: ${({ theme }) => theme.spacing(4)};
			min-height: 0;
		}
	}
`

export { MiddleAreaWrapper, RightWrapper }
